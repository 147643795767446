import axios from "axios";

export const User = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/user`,
});

export const Paymentlink = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/paymentlink`,
});

export const Order = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/order`,
});

export const Payout = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/payout`,
});

export const Transaction = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/transaction`,
});

export const Paymenthistory = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/paymenthistory`,
});

export const Smtp = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/smtp`,
});

export const History = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/history`,
});

export const Distributor = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/distributor`,
});

export const Recharge = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/recharge`,
});

export const Bbps = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/bbps`,
});

export const Paymentoption = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/paymentoption`,
});
