// /* eslint-disable eqeqeq */
// /* eslint-disable no-unused-vars */
// /* eslint-disable array-callback-return */
// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useRef, useState } from "react";
// import "./App.css";
// import Layout from "./pages/Layout";
// import Login from "./pages/Login";
// import { checkuserpaymentlink, updatePaymentlink } from "./Api/Paymentlink";
// import { CreteToken, StatusOrder } from "./Api/order";
// import { checkByUserPaymenthistory } from "./Api/paymenthistory";
// import { updateUser, viewByidUser } from "./Api/User";
// import { useDispatch } from "react-redux";
// import { storeAction } from "./Store/Store";
// import { createHistory } from "./Api/History";
// import { updateDistributor, viewByidDistributor } from "./Api/distributor";
// import moment from "moment";
// import { CreteTransaction } from "./Api/transaction";

// function App() {
//   const dispatch = useDispatch();
//   const didMountRef = useRef(true);

//   var [userid, setuserid] = useState(null);

//   useEffect(() => {
//     var userid = sessionStorage.getItem("user_id");
//     setuserid(userid);
//     getorderdata();
//     didMountRef.current = false;
//   }, [userid]);

//   const getorderdata = async () => {
//     var userid = sessionStorage.getItem("user_id");
//     if (userid !== null) {
//       var viewuser = await viewByidUser({ id: userid });
//       if (viewuser.length !== 0) {
//         dispatch(storeAction.balanceHandler({ balance: viewuser[0].balance }));
//         var allmydata = await checkuserpaymentlink({ id: userid });
//         if (allmydata.length !== 0) {
//           var tokenData = {
//             client_id: process.env.REACT_APP_CLIENT_ID,
//             username: process.env.REACT_APP_USER_NAME,
//             password: process.env.REACT_APP_PASSWORD,
//           };
//           var cretetoken = await CreteToken(tokenData);
//           if (cretetoken.status === "SUCCESS") {
//             for (const dataindex of allmydata) {
//               console.log(dataindex, "dataindex");
//               await processPaymentLink(
//                 dataindex,
//                 userid,
//                 cretetoken.data.token
//               );
//             }
//           }
//         }
//         setTimeout(() => {
//           getorderdata();
//         }, 5000);
//       }
//     }
//   };

//   const processPaymentLink = async (dataindex, userid, token) => {
//     try {
//       var datanew = {
//         orderid: dataindex.orderid,
//         client_id: process.env.REACT_APP_CLIENT_ID,
//         token: token,
//       };
//       var statusorder = await StatusOrder(datanew);
//       if (
//         statusorder.ORDERSTATUS &&
//         statusorder.ORDERSTATUS.STATUS === "SUCCESS"
//       ) {
//         var currentUser = await viewByidUser({ id: userid });
//         if (currentUser.length === 0) {
//           return;
//         }
//         var checkuser = await checkByUserPaymenthistory({
//           id: userid,
//           orderid: statusorder.ORDERSTATUS.ORDER_ID,
//         });

//         if (checkuser.length === 0) {
//           return;
//         }

//         var newBalance =
//           Number(currentUser[0].balance) + Number(checkuser[0].amount);
//         var historydata = {
//           user_id: userid,
//           opening_bal: currentUser[0].balance,
//           closing_bal: newBalance,
//           amount: checkuser[0].amount,
//           type: "paymentlink",
//         };

//         if (currentUser[0].dis_id !== null) {
//           var viewdistributor = await viewByidDistributor({
//             id: currentUser[0].dis_id,
//           });
//           if (viewdistributor.length === 0) {
//             return;
//           }
//           var newdistributordata = {
//             id: viewdistributor[0].id,
//             balance:
//               Number(viewdistributor[0].balance) +
//               Number(checkuser[0].distributoramount),
//           };
//           await updateDistributor(newdistributordata);
//         }
//         var transactionData = {
//           orderid: statusorder.ORDERSTATUS.ORDER_ID,
//           date: moment().format("YYYY-MM-DD HH:MM:SS"),
//           status: "Success",
//           mode: null,
//           urn_no: null,
//           Trans_date: moment().format("YYYY-MM-DD HH:MM:SS"),
//           user: userid,
//           name: dataindex.name,
//           user_id: userid,
//           opening_bal: currentUser[0].balance,
//           closing_bal: newBalance,
//           amount: checkuser[0].amount,
//         };
//         await CreteTransaction(transactionData);
//         await createHistory(historydata);
//         await updateUser({
//           id: userid,
//           balance: newBalance,
//         });
//         await updatePaymentlink({ id: dataindex.id, status: "Success" });
//       } else if (statusorder.ORDERSTATUS.STATUS === "FAILED") {
//         await updatePaymentlink({ id: dataindex.id, status: "Failed" });
//       } else {
//         await updatePaymentlink({ id: dataindex.id, status: dataindex.status });
//       }
//     } catch (error) {
//       await updatePaymentlink({ id: dataindex.id, status: "Failed" });
//     }
//   };

//   return <>{userid === null ? <Login /> : <Layout />}</>;
// }

// export default App;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import { checkuserpaymentlink, updatePaymentlink } from "./Api/Paymentlink";
import { CreteToken, StatusOrder } from "./Api/order";
import { checkByUserPaymenthistory } from "./Api/paymenthistory";
import { updateUser, viewByidUser } from "./Api/User";
import { useDispatch } from "react-redux";
import { storeAction } from "./Store/Store";
import { createHistory } from "./Api/History";
import { updateDistributor, viewByidDistributor } from "./Api/distributor";
import moment from "moment";
import { CreteTransaction } from "./Api/transaction";

function App() {
  const dispatch = useDispatch();
  const pollingInterval = useRef(null);

  const [userid, setUserId] = useState(null);

  useEffect(() => {
    // Retrieve user ID from session storage on component mount
    const storedUserId = sessionStorage.getItem("user_id");
    setUserId(storedUserId);

    // Start polling if a user ID exists
    if (storedUserId) {
      fetchOrderData(storedUserId);
      startPolling(storedUserId);
    }

    // Clean up on component unmount
    return () => {
      stopPolling();
    };
  }, []);

  const startPolling = (userid) => {
    // Poll the order data every 5 seconds
    pollingInterval.current = setInterval(() => {
      fetchOrderData(userid);
    }, 5000);
  };

  const stopPolling = () => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current);
      pollingInterval.current = null;
    }
  };

  const fetchOrderData = async (userid) => {
    try {
      const user = await viewByidUser({ id: userid });
      if (user && user.length > 0) {
        // Update Redux store with user's balance
        dispatch(storeAction.balanceHandler({ balance: user[0].balance }));

        // // Fetch all payment links for the user
        // const paymentLinks = await checkuserpaymentlink({ id: userid });
        // if (paymentLinks.length > 0) {
        //   // Fetch token for processing payment links
        //   const tokenData = {
        //     client_id: process.env.REACT_APP_CLIENT_ID,
        //     username: process.env.REACT_APP_USER_NAME,
        //     password: process.env.REACT_APP_PASSWORD,
        //   };
        //   const tokenResponse = await CreteToken(tokenData);
        //   if (tokenResponse.status === "SUCCESS") {
        //     // Process all payment links concurrently
        //     await Promise.all(
        //       paymentLinks.map((link) =>
        //         processPaymentLink(link, userid, tokenResponse.data.token)
        //       )
        //     );
        //   }
        // }
      }
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };

  // const processPaymentLink = async (link, userid, token) => {
  //   try {
  //     // Check the status of the payment order
  //     const orderStatus = await StatusOrder({
  //       orderid: link.orderid,
  //       client_id: process.env.REACT_APP_CLIENT_ID,
  //       token,
  //     });

  //     if (orderStatus.ORDERSTATUS?.STATUS === "SUCCESS") {
  //       // Handle successful payment
  //       const currentUser = await viewByidUser({ id: userid });
  //       if (currentUser && currentUser.length > 0) {
  //         const paymentHistory = await checkByUserPaymenthistory({
  //           id: userid,
  //           orderid: orderStatus.ORDERSTATUS.ORDER_ID,
  //         });

  //         if (paymentHistory.length > 0) {
  //           const newBalance =
  //             Number(currentUser[0].balance) + Number(paymentHistory[0].amount);

  //           // Update user's balance and history
  //           await updateUser({ id: userid, balance: newBalance });
  //           await createHistory({
  //             user_id: userid,
  //             opening_bal: currentUser[0].balance,
  //             closing_bal: newBalance,
  //             amount: paymentHistory[0].amount,
  //             type: "paymentlink",
  //           });

  //           // Update distributor's balance if applicable
  //           if (currentUser[0].dis_id) {
  //             const distributor = await viewByidDistributor({
  //               id: currentUser[0].dis_id,
  //             });
  //             if (distributor.length > 0) {
  //               await updateDistributor({
  //                 id: distributor[0].id,
  //                 balance:
  //                   Number(distributor[0].balance) +
  //                   Number(paymentHistory[0].distributoramount),
  //               });
  //             }
  //           }

  //           // Record the transaction
  //           await CreteTransaction({
  //             orderid: orderStatus.ORDERSTATUS.ORDER_ID,
  //             date: moment().format("YYYY-MM-DD HH:MM:SS"),
  //             status: "Success",
  //             mode: null,
  //             urn_no: null,
  //             Trans_date: moment().format("YYYY-MM-DD HH:MM:SS"),
  //             user: userid,
  //             name: link.name,
  //             user_id: userid,
  //             opening_bal: currentUser[0].balance,
  //             closing_bal: newBalance,
  //             amount: paymentHistory[0].amount,
  //           });

  //           // Update the payment link's status
  //           await updatePaymentlink({ id: link.id, status: "Success" });
  //         }
  //       }
  //     } else if (orderStatus.ORDERSTATUS?.STATUS === "FAILED") {
  //       // Update the payment link's status to "Failed"
  //       await updatePaymentlink({ id: link.id, status: "Failed" });
  //     } else {
  //       // Handle other statuses
  //       await updatePaymentlink({ id: link.id, status: link.status });
  //     }
  //   } catch (error) {
  //     console.error("Error processing payment link:", error);
  //     await updatePaymentlink({ id: link.id, status: "Failed" });
  //   }
  // };

  return <>{userid === null ? <Login /> : <Layout />}</>;
}

export default App;
