import React, { useEffect, useState } from "react";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { BsLink45Deg } from "react-icons/bs";
import { CreteToken, CreteOrder } from "../../Api/order";
import { CretePaymentlink } from "../../Api/Paymentlink";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { viewByidUser } from "../../Api/User";
import { CretePaymenthistory } from "../../Api/paymenthistory";
import copy from "copy-to-clipboard";
import { viewByidDistributor } from "../../Api/distributor";
import { viewbyuserdataPaymant } from "../../Api/paymentoption";

const Payment = () => {
  const [inputs, setInputs] = useState({
    amount: "",
    phone: "",
    paymentmethod: "",
  });
  const [errorlist, seterrorlist] = useState(null);
  const [paymentlink, setpaymentlink] = useState(null);
  const [paymentbtn, setpaymentbtn] = useState(false);
  const [paymentmethod, setpaymentmethod] = useState([]);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const submitbtn = async () => {
    var userid = sessionStorage.getItem("user_id");
    const { amount, phone, paymentmethod } = inputs;
    const newerrors = {};
    if (amount.length === 0) {
      newerrors.amount = "Please Enter Amount...";
    } else if (phone.length === 0) {
      newerrors.phone = "Please Enter Customer Mobile Number...";
    } else if (paymentmethod.length === 0) {
      newerrors.paymentmethod = "Please Select Payment Method...";
    }
    seterrorlist(newerrors);
    if (Object.keys(newerrors).length === 0) {
      setpaymentbtn(true);
      var data = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        username: process.env.REACT_APP_USER_NAME,
        password: process.env.REACT_APP_PASSWORD,
      };
      var cretetoken = await CreteToken(data);
      if (cretetoken.status === "SUCCESS") {
        var creteorder = await CreteOrder({
          amount: amount,
          order_id: `mespay${Math.random().toString(36).substring(2, 15)}`,
          token: cretetoken.data.token,
          client_id: process.env.REACT_APP_CLIENT_ID,
        });
        if (creteorder.status === "SUCCESS") {
          setpaymentlink(creteorder.paymentLink);
          var newdata = {
            amount: amount,
            phone: phone,
            user_id: userid,
            payment_link: creteorder.paymentLink,
            status: "Pending",
            orderid: creteorder.order_id,
          };
          var createdata = await CretePaymentlink(newdata);
          if (createdata.message === "SUCCESS") {
            var viewuser = await viewByidUser({ id: userid });
            if (viewuser.length !== 0) {
              if (viewuser[0].dis_id === null) {
                var pgamount =
                  Number(amount) -
                  (Number(amount) *
                    (Number(viewuser[0].charge) + Number(paymentmethod))) /
                    Number(100);
                var paymentdata = {
                  amount: pgamount,
                  orderid: creteorder.order_id,
                  user_id: userid,
                  status: "Pending",
                };
                CretePaymenthistory(paymentdata);
                setpaymentbtn(false);
                toast.success("Payment Link Generated Successfully...", {
                  autoClose: 2000,
                  transition: Slide,
                });
              } else {
                var viewdistributor = await viewByidDistributor({
                  id: viewuser[0].dis_id,
                });
                var dispgamount =
                  (Number(amount) * Number(viewuser[0].dis_charge)) /
                  Number(100);
                var distributoramount =
                  (Number(amount) *
                    (Number(viewdistributor[0].charge) +
                      Number(paymentmethod))) /
                  Number(100);
                const diff = (a, b) => {
                  return Math.abs(a - b);
                };
                var totalcomm = Number(dispgamount) + Number(distributoramount);
                var finalamount = Number(amount) - Number(totalcomm);
                var diffcommi = diff(
                  viewdistributor[0].charge,
                  viewuser[0].dis_charge
                );
                var distributorcommison =
                  (Number(dispgamount) * Number(diffcommi)) / Number(100);
                var finaldistributor =
                  Number(distributoramount) + Number(distributorcommison);

                var paymentdatanew = {
                  amount: finalamount,
                  distributoramount: finaldistributor,
                  orderid: creteorder.order_id,
                  user_id: userid,
                  status: "Pending",
                };
                CretePaymenthistory(paymentdatanew);
                setpaymentbtn(false);
                toast.success("Payment Link Generated Successfully...", {
                  autoClose: 2000,
                  transition: Slide,
                });
              }
            }
          }
        } else {
          toast.error(creteorder.message, {
            autoClose: 2000,
            transition: Slide,
          });
          setpaymentbtn(false);
        }
      } else {
        toast.error(cretetoken.message, {
          autoClose: 2000,
          transition: Slide,
        });
        setpaymentbtn(false);
      }
    }
  };
  const copyclipboard = async () => {
    copy(paymentlink);
    alert(`Payment Link Copied.."${paymentlink}"`);
  };
  const whstappopen = () => {
    window.open(
      `https://api.whatsapp.com/send?text=${paymentlink}`,
      "__target"
    );
  };

  useEffect(() => {
    Getalldata();
  }, []);

  const Getalldata = async () => {
    var alldata = await viewbyuserdataPaymant({
      id: sessionStorage.getItem("user_id"),
    });
    setpaymentmethod(alldata);
  };
  return (
    <>
      <h1 className="text-start font-bold text-xl mt-5">Create Payment Link</h1>
      <div className="w-full px-10 py-10">
        <div className="shadow-lg shadow-gray-400/100  w-3/6 bg-white flex flex-col px-5 py-5 rounded-md">
          <label className="mt-5 text-left py-1 font-bold">Amount</label>
          <div>
            <input
              className="border p-2 border-black rounded outline-none w-full"
              placeholder="Enter the amount"
              alt=""
              name="amount"
              onChange={handleChange}
            />
            {errorlist !== null ? (
              <div className="text-red-500 text-start">{errorlist.amount}</div>
            ) : null}
          </div>
          <div className="flex justify-between mt-5 px-1">
            <label className="py-1 font-bold">Customer Mobile Number</label>
          </div>
          <div>
            <input
              className="border p-2 border-black rounded outline-none w-full"
              placeholder="Enter Customer Mobile Number"
              alt=""
              name="phone"
              onChange={handleChange}
            />
            {errorlist !== null ? (
              <div className="text-red-500 text-start">{errorlist.phone}</div>
            ) : null}
          </div>
          <div className="flex justify-between mt-5 px-1">
            <label className="py-1 font-bold">Select Payment Method</label>
          </div>
          <div>
            <select
              className="border p-2 border-black rounded outline-none w-full"
              name="paymentmethod"
              onChange={handleChange}
            >
              <option value="">Select</option>
              {paymentmethod.length !== 0
                ? paymentmethod.map((data, index) => (
                    <option value={data.percentage} key={index}>
                      {data.name} ( {data.percentage}% )
                    </option>
                  ))
                : null}
            </select>
            {errorlist !== null ? (
              <div className="text-red-500 text-start">
                {errorlist.paymentmethod}
              </div>
            ) : null}
          </div>
          <div className="w-full my-2">
            {paymentbtn === false ? (
              <button
                className="bg-[#007DFA] font-semibold my-4 w-full h-10 rounded text-white"
                onClick={submitbtn}
              >
                Submit
              </button>
            ) : (
              <button
                className="bg-green-600 font-semibold my-4 w-full h-10 rounded text-white"
                // onClick={submitbtn}
              >
                Please Wait...
              </button>
            )}
          </div>
        </div>
        {paymentlink !== null ? (
          <div className=" shadow-lg shadow-gray-400/100 rounded-md   bg-white  px-5  my-6">
            <p className="text-blue-500 text-left py-1 text-lg ">
              Payment Link
            </p>
            <div className="flex justify-between pt-2 pb-4">
              <input
                type="url"
                placeholder="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEyIWiK8-Yz0mpAJaCzBcwRClyrtbUdM04lA&usqp=CAU"
                className="bg-slate-200 w-full mx-1 px-1 py-2 border-2 border-slate-200 rounded-sm outline-none "
                value={paymentlink}
                disabled
              />
              <div className="flex justify-evenly">
                <div
                  className="bg-green-400 w-10 h-10 flex justify-center items-center rounded-sm mx-1 cursor-pointer"
                  onClick={whstappopen}
                >
                  <AiOutlineWhatsApp className="text-white w-6 h-6" />
                </div>
                <div
                  className="w-10 h-10 flex justify-center items-center border-2 border-black rounded-sm mx-1 cursor-pointer"
                  onClick={copyclipboard}
                >
                  <BsLink45Deg className="text-black w-6 h-6" />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <ToastContainer />
    </>
  );
};

export default Payment;
