/* eslint-disable react-hooks/exhaustive-deps */
import {
  ArrowsRightLeftIcon,
  LinkIcon,
  RectangleStackIcon,
  BanknotesIcon,
  UserIcon,
  ListBulletIcon,
  DevicePhoneMobileIcon,
  CurrencyRupeeIcon,
  BookOpenIcon,
} from "@heroicons/react/24/outline";
import { BiChevronDown } from "react-icons/bi";
import { useEffect, useState } from "react";
import { CgLogOff } from "react-icons/cg";
import { useSelector } from "react-redux";

const SidebarNav = () => {
  const balancevalue = useSelector((store) => store.balance);
  const pg_balancevalue = useSelector((store) => store.pg_balance);
  const [toggle, setToggle] = useState(false);
  const [profile, setprofile] = useState(false);
  const [balance, setbalance] = useState(0);
  const [bg_balance, setbg_balance] = useState(0);
  const logoutbtn = () => {
    sessionStorage.removeItem("user_id");
    window.location.reload();
  };
  useEffect(() => {
    getalldata();
  }, [balancevalue, pg_balancevalue]);
  const getalldata = async () => {
    setbalance(balancevalue);
    setbg_balance(pg_balancevalue);
  };

  return (
    <>
      <div className="flex flex-col items-center my-8 text-xl">
        <ul className="flex flex-col  gap-5">
          <li
            className="flex gap-2 cursor-pointer"
            onClick={() => {
              window.location.replace("/");
            }}
          >
            <RectangleStackIcon className="h-6" />
            <strong>Dashboard</strong>
          </li>
          <li
            className="flex gap-2 cursor-pointer"
            onClick={() => window.location.replace("/bbps")}
          >
            <CurrencyRupeeIcon className="h-6" />
            <strong>BBPS Services</strong>
          </li>
          <li
            className="flex gap-2 cursor-pointer"
            onClick={() => window.location.replace("/bbps-history")}
          >
            <BookOpenIcon className="h-6" />
            <strong>BBPS History</strong>
          </li>
          <li
            className="flex gap-2 cursor-pointer"
            onClick={() => window.location.replace("/recharge")}
          >
            <DevicePhoneMobileIcon className="h-6" />
            <strong>Recharge</strong>
          </li>
          <li
            className="flex gap-2 cursor-pointer"
            onClick={() => window.location.replace("/recharge-history")}
          >
            <BookOpenIcon className="h-6" />
            <strong>Recharge History</strong>
          </li>
          <li className="flex gap-2 justify-between items-center cursor-pointer">
            <div
              className="flex gap-2 justify-center"
              onClick={() => window.location.replace("/user")}
            >
              <BanknotesIcon className="h-6" />
              <strong>Payments</strong>
            </div>
            <div className="items-center">
              <BiChevronDown
                className="w-4 h-4 bg-blue-400 text-white rounded-full"
                onClick={() => setToggle(!toggle)}
              />
            </div>
          </li>

          {toggle && (
            <ul className="top-10 cursor-pointer ml-7 text-start">
              <li onClick={() => window.location.replace("/payment")}>
                <strong>Payment Links</strong>
              </li>
              <li
                className="mt-3"
                onClick={() => window.location.replace("/payout")}
              >
                <strong>Payouts</strong>
              </li>
            </ul>
          )}
          <li
            className="flex gap-2 cursor-pointer"
            onClick={() => window.location.replace("/link")}
          >
            <LinkIcon className="h-6" />
            <strong>Payment Links</strong>
          </li>
          <li
            className="flex gap-2 cursor-pointer"
            onClick={() => window.location.replace("/transaction")}
          >
            <ArrowsRightLeftIcon className="h-6" />
            <strong>Transactions</strong>
          </li>
          <li
            className="flex gap-2 cursor-pointer"
            onClick={() => {
              window.location.replace("/historydata");
            }}
          >
            <ListBulletIcon className="h-6" />
            <strong>Transaction History</strong>
          </li>

          <li className="flex gap-2 justify-between items-center cursor-pointer relative">
            <div className="flex gap-2 justify-center">
              <UserIcon className="h-6 w-6" />
              <strong>Profile</strong>
            </div>
            <div className="items-center">
              <BiChevronDown
                className="w-4 h-4 bg-blue-400 text-white rounded-full"
                onClick={() => setprofile(!profile)}
              />
            </div>
          </li>

          {profile && (
            <ul className="top-0 cursor-pointer ml-7">
              <li
                className="text-left"
                onClick={() => window.location.replace("/passwordchange")}
              >
                <strong>Change Password</strong>
              </li>
            </ul>
          )}
          <li className="flex gap-2 cursor-pointer" onClick={logoutbtn}>
            <CgLogOff className="h-6 w-6" />
            <strong>Log out</strong>
          </li>
        </ul>
      </div>
      <div className="flex gap-2 cursor-pointer ml-5 footerdiv">
        <ul className="flex flex-col justify-end text-2xl ">
          <li className="flex">
            <strong>Balance :</strong>{" "}
            <strong className="pl-1">₹ {parseFloat(balance).toFixed(2)}</strong>{" "}
          </li>
          <li className="flex mb-5">
            <strong>PG Balance :</strong>{" "}
            <strong className="pl-1">
              ₹ {parseFloat(bg_balance).toFixed(2)}
            </strong>{" "}
          </li>
        </ul>
      </div>
    </>
  );
};

export default SidebarNav;
