import { Paymentoption } from "../axios";

export const viewAllPaymentoption = async (data) => {
  var viewAllPaymentoption = await Paymentoption.post(`/viewAll`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewAllPaymentoption;
};

export const viewbyuserdataPaymant = async (data) => {
  var viewbyuserdataPaymant = await Paymentoption.post(`/viewbyuserdata`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewbyuserdataPaymant;
};
